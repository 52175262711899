/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { theme } from '../../styles'
import companyLogo from '../../../static/logos/company-logo-violet.svg'
import Space from '../common/Space'
import Text from '../common/Text'
import Button from '../common/Button'
import MobileMenu from './MobileMenu'
import { HeaderNav, HeaderTextLink } from './HeaderNav'
import LottieLogo from '../common/LottieLogo'

import { useUserLang } from '../../context/UserLangContext'
import generateHeaderTranslations from '../../data/generateHeaderTranslations'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const Wrapper = styled.div`
  position: relative;
  height: 88px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-left: ${props => (props.isDai ? '0' : '-150px')};
  padding: 5px 71px 0px 71px;
  z-index: 3;
  pointer-events: auto;
  column-gap: 16px;

  ${props =>
    props.fixed &&
    `
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 3;
    `}

  @media (min-width: 1000px) {
    ${props => props.isDai && `border-bottom: 2px solid ${theme.color.orange300};`}
  }

  @media (min-width: 1000px) and (max-width: 1500px) {
    height: ${props => (props.hideHeaderFooterNav || props.hideHeaderNav ? '80px' : '132px')};
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background: linear-gradient(to right, #4cdac9, #45a2f8, #bd3ff9, #f560a8, #f8856c, #fbd346);
    width: 100vw;
  }

  ${p =>
    p.isAnyMenuOpen &&
    `
    pointer-events: none;
  `}

  @media (max-width: 1312px) {
    padding: 0px 30px;
  }

  @media (max-width: 999px) {
    height: ${p => (p.hideHeaderFooterNav || p.hideHeaderNav ? 'auto' : '80px')};
    margin: 0px;
    width: 100vw;
    margin-left: ${props => (props.isDai ? '0' : '-150px')};
    padding: 0px 24px;

    ${p =>
      p.isAnyMenuOpen &&
      `
        pointer-events: auto;
    `}
  }
  @media (max-width: 1050px) {
    padding: 0px 24px;
    margin-left: ${props => (props.isDai ? '0' : '-24px')};
  }
  @media (max-width: 800px) {
    .button {
      width: auto;
    }
  }

  .header-text-link a {
    text-decoration: none;
    display: flex;

    span {
      white-space: nowrap;
      padding: 2px 3px;
    }

    .external-link {
      color: ${theme.color.offBlack}!important;
    }

    &:hover {
      span {
        color: ${theme.color.white};

        @media (max-width: 999px) {
          color: ${theme.color.offBlack}!important;
        }
      }

      span:not(.external-link) {
        @media (min-width: 1000px) {
          color: ${theme.color.white}!important;
        }
      }
    }
    &:focus,
    &:hover,
    &:active {
      .external-link {
        top: 2.3em;
        left: 0em;
      }
    }
  }
`

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1500px) {
    display: none;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media (max-width: 1500px) {
    display: none;
  }
`

const Tablet = styled.div`
  display: none;

  @media (min-width: 1000px) and (max-width: 1500px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: ${props => (!props.hideHeaderFooterNav && !props.hideHeaderNav ? 'initial' : 'intial')};

    ${props =>
      !props.hideHeaderFooterNav &&
      !props.hideHeaderNav &&
      `
      flex-direction: column;
      gap: 16px;
    `}
  }
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`

const Gap = styled.div`
  display: flex;
  gap: 16px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const NavWrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 999px) {
    display: none;
  }
`

const AudioEyeLogo = styled.img`
  width: 132px;
`

const Header = ({
  homePage = false,
  hideHeaderFooterNav,
  hideHeaderNav,
  showCTA,
  showScanSiteCTA,
  showDownloadPDFCTA,
  showSecondaryCTA,
  removeLogoLink = false,
  message,
  messageLink,
  isDai,
  fixed,
  microSiteHeaderFooter,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAnyMenuOpen, setIsAnyMenuOpen] = useState(false)
  const [scanYourSiteUrl, setSiteScanUrl] = useState(false)
  const [utmParams, setUtmParams] = useState(null)
  const { userLang } = useUserLang()
  const languageData = generateHeaderTranslations({ userLang })
  const ariaLabels = generateAriaLabelTranslations({ userLang })

  const data = useStaticQuery(graphql`
    query Header {
      prismicHeaderNavigation {
        data {
          header_links {
            top_level_title
            url {
              ...LinkContent
            }
            fallback_url
            submenu
            category
            column
            section_header
            icon {
              alt
              url
              gatsbyImageData(width: 40)
            }
          }
        }
      }
    }
  `)

  useEffect(() => setUtmParams(JSON.parse(sessionStorage.getItem('utm_params'))), [])

  useEffect(() => {
    if (isDai) {
      setSiteScanUrl(`${window.location.pathname}#site-scan`)
    }
  }, [])

  const Logo = removeLogoLink ? (
    <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
  ) : (
    <a href={languageData?.logoLink} aria-label="AudioEye" alt="AudioEye logo homepage">
      <LottieLogo />
    </a>
  )

  const microSiteLinks = [
    {
      top_level_title: languageData?.aboutUsLinkText,
      url: {
        uid: userLang === 'en' ? '/about-us/' : `/${userLang}/about-us-${userLang}`,
        url: userLang === 'en' ? '/about-us/' : `/${userLang}/about-us-${userLang}`,
        type: 'page',
        target: null,
      },
    },
  ]

  return (
    <Wrapper
      role="banner"
      homePage={homePage}
      hideHeaderFooterNav={hideHeaderFooterNav}
      hideHeaderNav={hideHeaderNav}
      isAnyMenuOpen={isAnyMenuOpen}
      isDai={isDai}
      fixed={fixed}
    >
      <>
        {/* Desktop */}
        <Left>
          {Logo}
          <Space width={45} height={40} />
          {message && (
            <Link to={messageLink}>
              {' '}
              <Text bodyBold>{message}</Text>
            </Link>
          )}
          {!hideHeaderFooterNav && !hideHeaderNav && !microSiteHeaderFooter && (
            <NavWrapper role="navigation" aria-label={ariaLabels?.mainMenu}>
              <HeaderNav
                headerLinks={data.prismicHeaderNavigation.data.header_links}
                mobile={false}
                isAnyMenuOpen={isAnyMenuOpen}
                setIsAnyMenuOpen={setIsAnyMenuOpen}
              />
            </NavWrapper>
          )}
          {microSiteHeaderFooter && (
            <NavWrapper role="navigation" aria-label={ariaLabels?.mainMenu}>
              <HeaderNav
                headerLinks={microSiteLinks}
                mobile={false}
                isAnyMenuOpen={isAnyMenuOpen}
                setIsAnyMenuOpen={setIsAnyMenuOpen}
              />
            </NavWrapper>
          )}
        </Left>
        <Right>
          {!hideHeaderFooterNav && !hideHeaderNav && (
            <>
              <HeaderTextLink
                text={languageData?.loginLinkText}
                to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
                font="smallBodyMedium"
                anchorClassName="unstyled-link"
                loginLink
              />
              <Space width={8} />
            </>
          )}
          {showSecondaryCTA && (
            <Button
              text={languageData?.startFreeTrialButtonText}
              to="#get-started-free"
              size="small"
              buttonType="secondary"
            />
          )}
          {showCTA && utmParams?.utm_campaign !== 'toolbar_referral' && (
            <Button text={languageData?.scheduleDemoButtonText} to="#schedule-demo" size="small" />
          )}
          {showDownloadPDFCTA && (
            <Button
              text="Download PDF"
              buttontype="secondary"
              to="https://aeurl.xyz/digital-accessibility-index-2023-remediated-pdf"
              size="small"
            />
          )}
          {showScanSiteCTA && <Button text="Scan Your Site" to={scanYourSiteUrl} size="small" />}
        </Right>

        <Tablet hideHeaderFooterNav={hideHeaderFooterNav} hideHeaderNav={hideHeaderNav}>
          <Top>
            {Logo}
            <Space width={45} height={40} />
            {message && (
              <Link to={messageLink}>
                {' '}
                <Text bodyBold>{message}</Text>
              </Link>
            )}
            {!hideHeaderFooterNav && !hideHeaderNav && !microSiteHeaderFooter && (
              <Gap>
                <HeaderTextLink
                  text="Help Center"
                  to="https://help.audioeye.com/hc/en-us"
                  font="smallBodyMedium"
                  anchorClassName="unstyled-link"
                />
                <HeaderTextLink
                  text={languageData?.loginLinkText}
                  to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
                  font="smallBodyMedium"
                  anchorClassName="unstyled-link"
                  loginLink
                />
              </Gap>
            )}
            {microSiteHeaderFooter && (
              <Gap>
                <HeaderTextLink
                  text={languageData?.loginLinkText}
                  to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
                  font="smallBodyMedium"
                  anchorClassName="unstyled-link"
                  loginLink
                />
              </Gap>
            )}
          </Top>
          <Bottom>
            {!hideHeaderFooterNav && !hideHeaderNav && !microSiteHeaderFooter && (
              <NavWrapper role="navigation" aria-label={ariaLabels?.mainMenu}>
                <HeaderNav
                  headerLinks={data.prismicHeaderNavigation.data.header_links}
                  mobile={false}
                  isAnyMenuOpen={isAnyMenuOpen}
                  setIsAnyMenuOpen={setIsAnyMenuOpen}
                />
              </NavWrapper>
            )}
            {microSiteHeaderFooter && (
              <NavWrapper role="navigation" aria-label={ariaLabels?.mainMenu}>
                <HeaderNav
                  headerLinks={microSiteLinks}
                  mobile={false}
                  isAnyMenuOpen={isAnyMenuOpen}
                  setIsAnyMenuOpen={setIsAnyMenuOpen}
                />
              </NavWrapper>
            )}
            <Gap>
              {showSecondaryCTA && (
                <Button
                  text={languageData?.startFreeTrialButtonText}
                  to="#get-started-free"
                  size="x-small"
                  buttonType="secondary"
                />
              )}
              {showCTA && utmParams?.utm_campaign !== 'toolbar_referral' && (
                <Button text={languageData?.scheduleDemoButtonText} to="#schedule-demo" size="x-small" />
              )}
              {showDownloadPDFCTA && (
                <Button
                  text="Download PDF"
                  buttontype="secondary"
                  to="https://aeurl.xyz/digital-accessibility-index-2023-remediated-pdf"
                  size="x-small"
                />
              )}
              {showScanSiteCTA && <Button text="Scan Your Site" to={scanYourSiteUrl} size="x-small" />}
            </Gap>
          </Bottom>
        </Tablet>

        {/* Mobile */}
        <MobileMenu
          hideHeaderFooterNav={hideHeaderFooterNav}
          hideHeaderNav={hideHeaderNav}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          showCTA={showCTA}
          showScanSiteCTA={showScanSiteCTA}
          showDownloadPDFCTA={showDownloadPDFCTA}
          showSecondaryCTA={showSecondaryCTA}
          headerLinks={microSiteHeaderFooter ? microSiteLinks : data.prismicHeaderNavigation.data.header_links}
          isAnyMenuOpen={isAnyMenuOpen}
          setIsAnyMenuOpen={setIsAnyMenuOpen}
          removeLogoLink={removeLogoLink}
          isDai={isDai}
          microSiteHeaderFooter={microSiteHeaderFooter}
        />
      </>
    </Wrapper>
  )
}

export default Header
